import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { device } from "../../utils";
import imgContent from "../../assets/image/jpeg/hyal2.jpg";
import imgContent2 from "../../assets/image/jpeg/about-derma-clinic.jpg";

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;
  display: grid;
  grid-template-columns: auto auto;
  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Content2 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="#ffffff"
      className="position-relative"
      py={["0px", null, "80px", null, "130px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className=" position-static order-lg-1">
            <ImgContainer className=" pl-lg-5">
              <img
                src={imgContent2}
                alt=""
                className="img-fluid"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
                css={`
                  box-shadow: ${({ theme }) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 10px;
                `}
              />
            </ImgContainer>
          </Col>
          <Col lg="6" className="order-lg-2 mt-5 mt-lg-0">
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>
                What to expect from the Seventy Hyal Skin Booster procedure?
                </Title>
                <Text mb={4}> <br /> <br />
                Consultation: You will meet with one of our practitioners prior to treatment to discuss your goals, assess your skin condition, and determine if you are a suitable candidate for the procedure.<br /> <br />


Preparation: Before the procedure, the treatment area will be cleansed, and numbing cream will be applied to minimise discomfort during the injections.<br /> <br />


Injection: This is then injected into specific areas of the face using a fine needle. The injector will strategically place the skin booster to help treat the physiological depletion of hyaluronic acid (HA) within the skin. The supplementation of hyaluronic acid (HA) delivers moisture-surging hydration whilst simultaneously stimulating the production of new collagen and elastin.<br /> <br />



After the injections, Small, localised bumps may appear at the injection points as expected with any injectable procedure.These small bumps can last anywhere from 1 hour to 48 hours before dispersing under the skin.<br /> <br />


What to expect :Bruising is the most common side effect and usually shows immediately and will last for 3-5 days. There can also be some mild swelling and tenderness. This is all completely normal and generally reduces within a few days to a week. No recovery time is required, and you can return to your daily activities immediately. <br /> <br />


<b>Post-treatment care:</b> <br /> <br />

Ensure your hands are clean and sanitised before touching your face. <br /> <br />
-Do not apply any skincare products for 24 hours<br /> <br />

-Do not apply makeup for 24 hours.<br /> <br />
-Avoid exercise / sweating for 24 hours.<br /> <br />

-Avoid swimming, sauna’s and spa environments for 48 hours.<br /> <br />

-Golden Rule: Make sure that you are also avoiding direct sun exposure. Especially tanning beds. (if you must, please wait 7 days).<br /> <br />

-Stay hydrated. <br /> <br /> 

You'll receive instructions on how to care for your skin straight after the treatment as well as in our post treatment email.  <br /> <br />

The longevity of results can vary between individuals however Results are expected to show almost immediately with full results showing two weeks post-treatment. 2-3 sessions of the treatment depending on initial skin quality spaced four weeks apart may be recommended to maintain the desired results.Treatments will then need to be topped up every six to 12 months.
                </Text>

         
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
