import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { device } from "../../utils";
import imgContent from "../../assets/image/jpeg/hyal2.jpg";

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;
  display: grid;
  grid-template-columns: auto auto;
  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 18px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 12px;
      width: 14px;
      height: 14px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="#ffffff"
      className="position-relative"
      py={["0px", null, "80px", null, "130px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className=" position-static order-lg-2">
            <ImgContainer className=" pl-lg-5">
              <img
                src={imgContent}
                alt=""
                className="img-fluid"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
                css={`
                  box-shadow: ${({ theme }) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 10px;
                `}
              />
            </ImgContainer>
          </Col>
          <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>
                How Does Seventy Hyal Skin Booster Work?
                </Title>
                <Text mb={4}>
                Seventy Hyal 2000 is an injectable hyaluronic acid skin booster
treatment designed to address the natural decline of hyaluronic acid
(HA) in the skin. As we age, we lose approximately 1% of our Hyaluronic
Acid (HA) stores per year after the age of 30, This leads to the
appearance of fine lines and a decrease in facial volume. <br /> <br /> Seventy Hyal
2000 with its high molecular weight of Hyaluronic Acid (HA) (&gt;2000 kDa)
addresses reduction of hyaluronic acid in the skin by reintroducing it into
the dermis through infiltration and concurrently stimulating the
generation of fresh collagen and elastin.
                </Text>

                <ULStyled>
                  <li>Skin Rejuvenation</li>
                  <li>Hydrating</li>
                  <li>Contouring</li>
                  <li>Radiant Glow</li>
                </ULStyled>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
