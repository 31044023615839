import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import HeroComponent from "../components/HeroComponent";
import LipsGlance from "../components/glance.js";
import Why from "../components/why.js";
import Accordion from "../components/Accordion.js";
import imgVideo from "../assets/image/jpeg/lip-filler-doctor.png";
import Testimonial from "../sections/index/indexreviews.js";
import Saveface from '../components/save.js';
import Trend from '../components/trends.js';
import Content from "../sections/hyal/hyalcontent.js";
import Content2 from "../sections/hyal/hyalcontent2.js";
import HyalFaq from "../sections/hyal/hyalfaq.js";
import Head from "../components/head";
import Clinic from '../components/NewFind.js';  
import Award from "../components/NewToxane.js";
import { JsonLd } from '../components/JsonLd';
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const SeventyPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const accordionItems = [
    { title: "What is Seventy Hyal 2000?", content: <Content /> },
    { title: "What to expect from the procedure?", content: <Content2 /> },
    { title: "Why choose Dermamina", content:<Why />, isLast: true},
  ];
  return (
    <>
 <Head title="Seventy Hyal 2000 Treatment London" image="/images/dermamina-home.jpg"  description="experts in Seventy Hyal 2000 London" keywords="Hyaluronic Acid, Skin Rejuvenation, anti-ageing, Hydration Boost, Fine Line Reduction, Collagen Stimulation, Dermal Filler, Skin Plumping, Wrinkle Smoothing, Facial Volume Restoration, Seventy Hyal 2000"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
'@type': 'ContactPoint',
telephone: '020 7099 6650',
contactType: 'Customer service',
},
address:{
'@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
{'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Seventy Hyal 2000 London',
item:'https://www.dermamina.com/Seventy Hyal 2000',
},

]
}}
</JsonLd>


<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Seventy Hyal 2000</span> <span style="color: #00aec7;">Treatment</span>'
    secondText="Advanced skin booster of the next generation"
    videoUrl="https://www.youtube.com/watch?v=WOMtfqK3vOw" // Specify your video URL here
    videoAlt="Skin Boosters Seventy Hyal 2000 Video"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5 pb-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<LipsGlance
   best="2-3 treatments"
  bestalt="best Seventy Hyal 2000 therapy"
  resultstext="6-12 months"
  resultsalt="Seventy Hyal 2000 therapy results"
  costdata="£300"
  costalt="Seventy Hyal 2000 Cost"
  timedata="20 Minutes"
  timealt="Seventy Hyal 2000 Duration Time"
  workdata="Immediately"
  workalt="Seventy Hyal 2000 downtime work"
  paindata="None / Mild"
  painalt="Seventy Hyal 2000 Pain"
  />
 </Col>
 <Col className="d-lg-none d-xl-none" lg="6" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<Accordion items={accordionItems} /></Col>
</Row>
</Container>
</Section>
<Testimonial />
<HyalFaq
  setIsModalOpen={setIsModalOpen}
/>
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default SeventyPage;
